import { makeStyles, Theme, ThemeProvider } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { theme } from '../app_utils/muiTheme';
import ZHeader from '../components/ZAppbar';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    marginTop: 71,
    backgroundColor: theme.palette.background.default,
    overflowX: 'auto',
    overflowY: 'auto'
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <ThemeProvider theme={theme}>
        <ZHeader siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main className={classes.main}>{children}</main>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default Layout;
