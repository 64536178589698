import React, { ReactNode } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline, StylesProvider } from '@material-ui/core';

import { theme } from './muiTheme';
import Layout from '../layout/layout';

const wrapPageElement = ({ element }: { element: ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout>{element}</Layout>
    </ThemeProvider>
  );
};

export default wrapPageElement;
