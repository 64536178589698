import { Grid, Link } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import React from 'react';
import ZAppBarDrawerMobile from './ZAppBarDrawerMobile';
import Img from 'gatsby-image';
import logo from '../images/logo.png';

export default function ZHeader({ siteTitle }) {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1600) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const stanna = data.allImageSharp.nodes.find((n) => n.fluid.originalName === 'logo.png').fluid;

  const classes = useStyles();
  const [drawerTemporary, setDrawerTemporary] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleTemporaryDrawer = () => {
    setDrawerTemporary((prev) => !prev);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  return (
    <>
      <AppBar className={classes.appBar} position='fixed' elevation={0}>
        <Grid className={classes.toolBar} container justify='space-between' alignContent='center'>
          <Grid container alignContent='center' item xs={4}>
            <Link to='/' component={GatsbyLink} color='inherit'>
              <img className={classes.logo} src={logo} />
            </Link>
          </Grid>

          <Grid className={classes.sectionDesktop} container item xs={8} justify='flex-end' alignContent='center'>
            <Link to='/' className={classes.link} activeClassName={classes.linkActive} component={GatsbyLink} color='inherit'>
              <Typography className={classes.linkText}>Home</Typography>
            </Link>
            <Link to='/services' className={classes.link} activeClassName={classes.linkActive} component={GatsbyLink} color='inherit'>
              <Typography className={classes.linkText}>Services</Typography>
            </Link>
            <Link to='/meet-the-team' className={classes.link} activeClassName={classes.linkActive} component={GatsbyLink} color='inherit'>
              <Typography className={classes.linkText}>Meet the Team</Typography>
            </Link>
            <Link to='/faq' className={classes.link} activeClassName={classes.linkActive} component={GatsbyLink} color='inherit'>
              <Typography className={classes.linkText}>FAQ</Typography>
            </Link>
            <Link to='/contact-us' className={classes.link} component={GatsbyLink} color='inherit'>
              <Typography className={classes.linkTextContact}>Contact</Typography>
            </Link>
          </Grid>

          <Grid>
            <IconButton className={classes.sectionMobile} onClick={handleTemporaryDrawer} color='inherit'>
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      <ZAppBarDrawerMobile open={drawerTemporary} setOpen={setDrawerTemporary} />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: 'white',
      height: 70,
      borderBottom: `1px solid #eee`
    },
    logo: {
      maxWidth: 200
    },
    toolBar: {
      padding: 0,
      color: '#000',
      maxWidth: 1400,
      paddingRight: 10,
      paddingLeft: 10,
      margin: 'auto'
    },
    linkTitle: {
      display: 'flex',
      alignContent: 'center',
      textDecoration: `none`,
      textTransform: `capitalize`,
      color: `black`,

      fontWeight: 400,
      flexGrow: 1
    },
    link: {
      textDecoration: `none`,
      textTransform: `capitalize`,
      color: '#555',
      display: 'flex',
      alignContent: 'center',
      '&:hover': {
        textDecoration: `none`
      }
    },
    linkActive: {
      color: `black`
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `capitalize`,

      fontWeight: 800,
      marginLeft: 20,
      padding: 5,
      lineHeight: 1,
      margin: 'auto',
      '&:hover': {
        color: `black`
      }
    },
    linkTextContact: {
      textDecoration: `none`,
      textTransform: `capitalize`,
      color: `white`,
      backgroundColor: '#136ECF',

      fontWeight: 800,
      marginLeft: 20,
      padding: '5px 10px',

      borderRadius: 20,
      '&:hover': {
        textDecoration: `none`
      }
    },
    sectionMobile: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block'
      },
      color: 'black'
    },
    title: {
      color: '#008C9E',

      fontWeight: 500
    },
    sectionDesktop: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },

    contactButton: {
      color: '#008C9E',

      fontWeight: 500,
      height: 30,
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2)
    }
  })
);
