import { Link, Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

const drawerWidth = 300;

interface PropsTemp {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ZAppBarDrawerMobile: React.FC<PropsTemp> = ({ open, setOpen }) => {
  const classes = useStyles();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  return (
    <React.Fragment key={'mobileDrawer'}>
      <Drawer className={classes.drawer} anchor='left' open={open} onClose={toggleDrawer(false)}>
        <div className={classes.drawerWidth} />

        <Link to='/services' className={classes.link} onClick={() => setOpen(false)} component={GatsbyLink} color='inherit'>
          <Typography className={classes.linkText}>Services</Typography>
        </Link>
        <Link to='/meet-the-team' className={classes.link} onClick={() => setOpen(false)} component={GatsbyLink} color='inherit'>
          <Typography className={classes.linkText}>Meet the Team</Typography>
        </Link>
        <Link to='/faq' className={classes.link} onClick={() => setOpen(false)} component={GatsbyLink} color='inherit'>
          <Typography className={classes.linkText}>FAQ</Typography>
        </Link>
        <Link to='/contact-us' className={classes.link} onClick={() => setOpen(false)} component={GatsbyLink} color='inherit'>
          <Typography className={classes.linkTextContact}>Contact</Typography>
        </Link>
      </Drawer>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerWidth: {
      width: drawerWidth
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      [theme.breakpoints.down('md')]: {
        display: 'flex'
      }
    },

    link: {
      textDecoration: `none`,
      textTransform: `capitalize`,
      '&:hover': {
        textDecoration: `none`
      }
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `capitalize`,
      color: `black`,

      fontWeight: 700,
      padding: '15px 25px',
      '&:hover': {
        textDecoration: `none`
      }
    },
    linkTextContact: {
      textDecoration: `none`,
      textTransform: `capitalize`,
      color: `black`,

      fontWeight: 700,
      padding: '15px 25px',
      '&:hover': {
        textDecoration: `none`
      }
    }
  })
);

export default ZAppBarDrawerMobile;
