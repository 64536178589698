import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

export const theme = createMuiTheme({
  breakpoints: {
    values: { xs: 0, sm: 600, md: 960, lg: 1400, xl: 1920, tablet: 400, laptop: 900, desktop: 1200 }
  },
  typography: {
    allVariants: {
      lineHeight: 2.0
    }
  },
  props: {
    MuiInput: { disableUnderline: true },
    MuiButtonBase: { disableRipple: true },
    MuiPaper: { elevation: 4 },
    MuiCard: { elevation: 4 },
    MuiDivider: { color: 'white' },
    MuiOutlinedInput: {}
  },
  overrides: {
    MuiPaper: {
      root: {
        elevation: 4
      }
    },
    MuiCard: {
      root: {
        elevation: 4
      }
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize'
      }
    }
  },
  palette: {
    background: {
      default: '#FFF'
    },
    primary: {
      main: '#542c85'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    }
  }
});

export const breakpoint = {
  xs: `(min-width: 0px)`,
  sm: `(min-width: 576px)`,
  md: `(min-width: 768px)`,
  lg: `(min-width: 992px)`,
  xl: `(min-width: 1200px)`
};
